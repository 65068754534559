import * as React from 'react';
import { Body } from './PageBody.styles';

interface PageBodyProps {
  children: React.ReactNode | React.ReactNode[];
}

export const PageBody = (props: PageBodyProps) => {
  const { children } = props;
  return <Body>{children}</Body>;
};
