import * as React from 'react';
import { theme } from '../../common/common';
import { Container, Footer } from '../../components';
import { pos } from './Pos.data';
import {
  FlexOne,
  FlexTwo,
  VmBigHeader,
  VmInfoContent,
  VmPos,
  VmPosImageBg,
  VmPosImage,
  VmPosTiles,
  VmPosTile,
  VmPosTileHeader,
  VmPosTileText,
  VmIPADImage,
  VmHeader,
} from './Pos.styles';
import { PosProps } from './Pos.types';

export const PosPage = (props: PosProps) => {
  const { history } = props;
  React.useEffect(() => {
    const element = document.getElementById('home-page');
    if (element) {
      element.scrollTo(0, 0);
    }
  }, []);
  const renderPos = () => {
    return (
      <VmPosImageBg>
        <div>
          <VmBigHeader className="big-header" color={theme.colorWhite}>
            {pos.sectionOne.header}
          </VmBigHeader>
          <VmInfoContent
            className="big-info"
            width={'80%'}
            color={theme.colorWhite}
          >
            {pos.sectionOne.infoOne}
          </VmInfoContent>
          <VmInfoContent
            className="big-info"
            width={'80%'}
            color={theme.colorWhite}
          >
            {pos.sectionOne.infoTwo}
          </VmInfoContent>
        </div>
        <div>
          <VmPosImage>
            <img src={pos.sectionOne.image} />
          </VmPosImage>
        </div>
      </VmPosImageBg>
    );
  };

  const renderCompanion = () => {
    return (
      <Container
        background={'none'}
        flexDirection={'row'}
        flexDirectionMobile={'column'}
      >
        <FlexTwo>
          <div>
            <VmIPADImage src={pos.sectionTwo.image} />
          </div>
        </FlexTwo>
        <FlexOne>
          <VmHeader>{pos.sectionTwo.header}</VmHeader>
        </FlexOne>
      </Container>
    );
  };

  const renderPosTiles = () => {
    const renderTile = (value: any, idx: number) => {
      return (
        <VmPosTile key={`tile-${idx}`}>
          <VmPosTileHeader>{value.header}</VmPosTileHeader>
          <VmPosTileText>{value.data}</VmPosTileText>
        </VmPosTile>
      );
    };
    return <VmPosTiles>{pos.sectionThree.map(renderTile)}</VmPosTiles>;
  };

  return (
    <VmPos>
      {renderPos()}
      {renderCompanion()}
      {renderPosTiles()}
      <Footer history={history} />
    </VmPos>
  );
};
