import styled from 'styled-components';
import { theme } from '../../common/common';

export const VmFooterWrapper = styled.div`
  background: ${theme.colorBlack};
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
`;

export const VmFooter = styled.div`
  margin: 0 4rem;
  display: flex;
  flex-wrap: nowrap;
  @media (max-width: 768px) {
    margin: 0 1rem;
    flex-direction: column;
    padding: 1rem 0;
  }
`;

export const VmFooterBox = styled.div`
  flex: 1 1 33%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: ${theme.colorWhite};
`;

export const VmFooterHeader = styled.div`
  font-size: 2rem;
  font-weight: 600;
  padding: 1rem 0;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.4rem;
  }
`;

export const VmFooterText = styled.div`
  padding: 0.5rem 0 ;
  font-size: 1.2rem;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const VmFooterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
