import styled, { css } from 'styled-components';
import { theme } from '../../common/common';
import { backOffice } from './Backoffice.data';

interface BigHeaderProps {
  color?: string;
}

interface InfoCssProps {
  width?: string;
  color?: string;
  align?: string;
  alignMobile?: string;
}

export const VmBackOffice = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${backOffice.mainBackground});
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  background-position: center;
  @media (max-width: 768px) {
    background-size: cover;
    background-position: center;
    background-position-x: 51%;
  }
`;

export const VmBackOfficerImageBg = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem;
  position: relative;
  min-height: calc(100vh - 6rem);
  z-index: 1;
  max-width: 100rem;
  margin: 0 auto;
  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(${backOffice.sectionOne.background});
    opacity: 0.17;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: 768px) {
    height: auto;
    justify-content: space-around;
    flex-direction: column;
    padding: 1rem;
    &:after {
      background-position: center;
    }
  }
`;

export const VmBigHeader = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    &.big-header {
      font-size: 3rem;
    }
    &.lpr {
      text-align: center;
    }
  }
`;

export const VmInfoContent = styled.div`
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: ${(props: InfoCssProps) =>
    props.color ? props.color : theme.colorWhite};
  width: ${(props: InfoCssProps) => (props.width ? props.width : '60%')};
  text-align: ${(props: InfoCssProps) => (props.align ? props.align : 'left')};
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
    ${(props: InfoCssProps) =>
      props.alignMobile &&
      css`
        text-align: ${props.alignMobile};
      `}
    &.lpr {
      text-align: center;
    }
    &.big-info {
      font-size: 1.4rem;
    }
  }
`;

export const VmLprImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    padding: 1rem 0;
    img {
      padding-bottom: 0rem;
      width: 100%;
    }
  }
`;

export const FlexOne = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FlexTwo = styled.div`
  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const VmBackOfficeSections = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const VmSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 4rem 3rem 0;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0.5rem 0rem;
  }
`;

export const VmSectionHeader = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const VmSectionInfo = styled.div`
  width: 70%;
  font-size: 1.3rem;
  text-align: left;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
  }
`;
