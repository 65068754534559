import styled, { css } from 'styled-components';
import { theme } from '../../common/common';
import lprBg from './../../common/assets/lpr/lprBg.png';
import { lpr } from './LprPage.data';

interface BigHeaderProps {
  color?: string;
}

interface InfoCssProps {
  width?: string;
  color?: string;
  align?: string;
  alignMobile?: string;
}

export const VmLpr = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${lprBg});
  background-repeat: no-repeat;
  background-size: 100vw 101%;
  background-position: center;
  background-position-y: -1rem;
  @media (max-width: 768px) {
    background-size: auto;
    background-position: center;
    background-position-y: -1rem;
    background-position-x: 52%;
  }
`;

export const VmBigHeader = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    &.big-header {
      font-size: 3rem;
    }
    &.lpr {
      text-align: center;
    }
  }
`;

export const VmInfoContent = styled.div`
  font-size: 1.5rem;
  color: ${(props: InfoCssProps) =>
    props.color ? props.color : theme.colorWhite};
  width: ${(props: InfoCssProps) => (props.width ? props.width : '60%')};
  text-align: ${(props: InfoCssProps) => (props.align ? props.align : 'left')};
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
    ${(props: InfoCssProps) =>
      props.alignMobile &&
      css`
        text-align: ${props.alignMobile};
      `}
    &.lpr {
      text-align: center;
    }
    &.big-info {
      font-size: 1.4rem;
    }
    &.padding {
      padding-right: 1rem;
    }
  }
`;

export const VmLprImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  img {
    max-width: 100%;
    padding-bottom: 2rem;
    max-height: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &.fit-content {
    width: 70%;
    margin: auto;
    padding: 1.5rem;
  }
  @media (max-width: 768px) {
    padding: 1rem 0;
    &.fit-content {
      width: 100%;
      padding: 1rem 0;
    }
  }
`;

export const VmActivityAudit = styled.div`
  display: flex;
  justify-content: space-between;
  &.width {
    padding: 0 6rem;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
`;

export const VmAudit = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${theme.colorWhite};
  padding-top: 4rem;
  @media (max-width: 768px) {
    padding-top: 1rem;
  }
`;

export const VmAuditInner = styled.div`
  padding: 4rem 0;
  text-align: center;
  flex: 1 1 33%;
  font-size: 3rem;
  color: ${theme.colorWhite};
  font-weight: 600;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2rem;
    padding: 2rem 0;
  }
`;

export const VmLprImageBg = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem;
  position: relative;
  height: calc(100vh - 6rem);
  max-width: 100rem;
  margin: 0 auto;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(${lpr.sectionOne.background});
    opacity: 0.5;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 4rem);
    justify-content: space-around;
    padding: 1rem;
    &:after {
      background-position: center;
    }
  }
`;
