import styled from 'styled-components';
import { theme } from '../../common/common';

export const VmMenuItem = styled.div`
  display: flex;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 3rem;
  width: max-content;
  text-align: left;
`;

export const VmMenuItx = styled.div`
  padding: 0.5rem 1rem;
  :not(:last-child) {
    border-bottom: 1px solid ${theme.colorGrey};
  }
  :hover {
    background: ${theme.colorGrey};
    font-weight: 600;
  }
`;

export const VmMenuList = styled.div`
  background: ${theme.colorWhite};
  padding: 0.5rem;
  box-shadow: 1px 2px 8px ${theme.colorGrey};
  border-radius: 0.3rem;
  min-width: 18rem;
`;
