import * as React from 'react';
import ReactDOM from 'react-dom';
import logo from './logo.png';
import { GlobalStyles } from './style';
import { HomePage } from './pages';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { ParallaxProvider } from 'react-scroll-parallax';

const history = createBrowserHistory();

const renderFavIcon = () => {
  const $favIcon = document.createElement('link');
  $favIcon.rel = 'icon';
  $favIcon.href = logo;
  document.head.appendChild($favIcon);
};

ReactDOM.render(
  <ParallaxProvider>
    <Router history={history}>
      <div>
        <HomePage />
      </div>
      {renderFavIcon()}
      <GlobalStyles />
    </Router>
  </ParallaxProvider>,
  document.getElementById('root')
);
