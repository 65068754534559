import * as React from 'react';
import { theme } from '../../common/common';
import { Container, Footer } from '../../components';
import { backOffice } from './Backoffice.data';
import { BackOfficeProps } from './Backoffice.types';
import {
  FlexOne,
  FlexTwo,
  VmBackOffice,
  VmBackOfficerImageBg,
  VmBackOfficeSections,
  VmBigHeader,
  VmInfoContent,
  VmLprImage,
  VmSection,
  VmSectionHeader,
  VmSectionInfo,
} from './styles';

export const BackOfficePage = (props: BackOfficeProps) => {
  const { history } = props;

  React.useEffect(() => {
    const element = document.getElementById('home-page');
    if (element) {
      element.scrollTo(0, 0);
    }
  }, []);

  const renderBackOffice = () => {
    return (
      <VmBackOfficerImageBg>
        <FlexOne>
          <VmBigHeader className="big-header" color={theme.colorBlack}>
            {backOffice.sectionOne.header}
          </VmBigHeader>
          <VmInfoContent
            className="big-info"
            width={'100%'}
            color={theme.colorBlack}
          >
            {backOffice.sectionOne.infoOne}
          </VmInfoContent>
          <VmInfoContent
            className="big-info"
            width={'100%'}
            color={theme.colorBlack}
          >
            {backOffice.sectionOne.infoTwo}
          </VmInfoContent>
          <VmInfoContent
            className="big-info"
            width={'100%'}
            color={theme.colorBlack}
          >
            {backOffice.sectionOne.infoThree}
          </VmInfoContent>
        </FlexOne>
        <FlexTwo className="centered">
          <VmLprImage className="fit-content">
            <img
              src={backOffice.sectionOne.image}
              alt="backOffice-background"
            />
          </VmLprImage>
        </FlexTwo>
      </VmBackOfficerImageBg>
    );
  };

  const renderBackOfficeSections = () => {
    const renderSection = (value: any, idx: number) => {
      return (
        <VmSection key={`section-${idx}`}>
          <VmSectionHeader>{value.header}</VmSectionHeader>
          <VmSectionInfo>{value.info}</VmSectionInfo>
        </VmSection>
      );
    };
    return (
      <Container background={'none'} flexDirectionMobile={'column'}>
        <VmBigHeader color={theme.colorBlack}>
          {backOffice.sectionTwo.header}
        </VmBigHeader>
        <VmBackOfficeSections>
          {backOffice.sectionTwo.data.map(renderSection)}
        </VmBackOfficeSections>
      </Container>
    );
  };

  return (
    <VmBackOffice>
      {renderBackOffice()}
      {renderBackOfficeSections()}
      <Footer history={history} />
    </VmBackOffice>
  );
};
