import { History } from 'history';
interface FooterProps {
  header: string;
  data: FooterDataProps[];
}

export interface FooterDataProps {
  label: string;
  onClick: (history: History, path: string) => void;
  path: string;
}

const onFooterClick = (history: History, path: string) => {
  history.push(path);
};

const onContactClick = (history: History, path: string) => {
  window.FreshworksWidget('open');
};

const onOpenSiteClick = (history: History, path: string) => {
  window.open(path, '_blank');
};

export const footerData: FooterProps[] = [
  {
    header: 'SITE MAP',
    data: [
      {
        label: 'Home',
        onClick: onFooterClick,
        path: '/solutions',
      },
      {
        label: 'Contact Us',
        onClick: onContactClick,
        path: '/contact',
      },
    ],
  },
  {
    header: 'PRODUCTS',
    data: [
      {
        label: 'Back Office',
        onClick: onOpenSiteClick,
        path: 'https://backoffice.valetmanager.com/',
      },
      {
        label: 'Reservations',
        onClick: onOpenSiteClick,
        path: 'https://reservations.valetmanager.com/',
      },
      {
        label: 'Monthly',
        onClick: onOpenSiteClick,
        path: 'https://monthly.valetmanager.com/',
      },
      {
        label: 'Hotels',
        onClick: onOpenSiteClick,
        path: 'https://hotel.valet-manager.com',
      },
    ],
  },
  {
    header: 'FEATURE DETAIL',
    data: [
      {
        label: 'License Plate Recognition',
        onClick: onFooterClick,
        path: '/lpr',
      },
      {
        label: 'Back Office',
        onClick: onFooterClick,
        path: '/backoffice',
      },
      {
        label: 'Reservations',
        onClick: onFooterClick,
        path: '/reservations',
      },
      {
        label: 'Monthly',
        onClick: onFooterClick,
        path: '/monthly',
      },
      {
        label: 'Point of Sale',
        onClick: onFooterClick,
        path: '/pos',
      },
    ],
  },
];

export const footerData1 = [
  {
    header: 'BACK OFFICE MANAGEMENT',
    data: ['Payroll', 'Analytics', 'Managing Rates', 'Reports', 'more...'],
  },
  {
    header: 'GARAGE MANAGEMENT',
    data: [
      'POS',
      'License Plate Recognition',
      'Gate Arm',
      'Touchless Ticketing',
      'more...',
    ],
  },
  {
    header: 'PUBLIC PORTALS',
    data: ['Reservations', 'Monthly Accounts', 'Company Websites', 'more...'],
  },
];
