import bgMain from './../../common/assets/reservations/bgMain.png';
import bgSub from './../../common/assets/reservations/bgSub.png';
import mobReservation from './../../common/assets/reservations/mobReservation.png';
import reservationSite from './../../common/assets/reservations/reservationSite.png';
import wallet from './../../common/assets/reservations/wallet.png';

export const reservation = {
  mainBackground: bgMain,
  sectionOne: {
    header: 'RESERVATIONS PORTAL',
    background: bgSub,
    image: mobReservation,
    infoOne:
      'Our Reservations portal allows you to directly interact with your customers.',
    infoTwo:
      'Customers can directly book a parking through Reservations website on their laptop or mobile.',
    infoThree:
      'Reservations portal makes booking and managing reservations easy.',
  },
  sectionTwo: {
    header: 'A WEB SOLUTION TO MANAGE BOOKINGS',
    image: reservationSite,
    infoOne:
      'Use our Reservations portal to exponentially improve customer booking performance.',
    infoTwo: 'Our Reservations portal allows your customers to',
    infoThree: [
      'Book Parking spots in your garages',
      'Make online payments',
      'Deploy eTickets',
    ],
  },
  sectionThree: {
    header: 'A MODERN SOLUTION FOR A MODERN WORLD',
    image: wallet,
    subHeader: 'Wallet & Pay Integration',
    infoOne:
      'Reservations Portal works seamlessly on mobile and effectively utilise Wallet and Pay services from Apple & Google.',
  },
};
