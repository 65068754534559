import * as React from 'react';
import { theme } from '../../common/common';
import { Container, Footer } from '../../components';
import { monthly } from './Monthly.data';
import {
  VmBigHeader,
  VmInfoContent,
  VmMonthly,
  VmLprImage,
  VmMonthlyWrapper,
} from './styles';

export const MonthlyPage = (props: any) => {
  React.useEffect(() => {
    const element = document.getElementById('home-page');
    if (element) {
      element.scrollTo(0, 0);
    }
  }, []);

  const renderMonthly = () => {
    return (
      <VmMonthlyWrapper>
        <div>
          <VmBigHeader color={theme.colorBlack}>
            {monthly.sectionOne.header}
          </VmBigHeader>
          <VmInfoContent color={theme.colorBlack} width={'90%'}>
            {monthly.sectionOne.infoOne}
          </VmInfoContent>
          <VmInfoContent color={theme.colorBlack} width={'90%'}>
            {monthly.sectionOne.infoTwo}
          </VmInfoContent>
        </div>
        <div>
          <VmLprImage>
            <img src={monthly.sectionOne.image} />
          </VmLprImage>
        </div>
      </VmMonthlyWrapper>
    );
  };

  const renderFooter = () => {
    return <Footer history={props.history} />;
  };

  return (
    <VmMonthly>
      {renderMonthly()}
      {renderFooter()}
    </VmMonthly>
  );
};
