import boBackground from './../../common/assets/backoffice/boBackground.png';
import boMain from './../../common/assets/backoffice/boMain.png';
import boSystem from './../../common/assets/backoffice/boSystem.png';

export const backOffice = {
  mainBackground: boMain,
  sectionOne: {
    header: 'BACK OFFICE',
    background: boBackground,
    image: boSystem,
    infoOne:
      'An ERP solution customized for the unique needs of a Parking Garage back office.',
    infoTwo:
      'The Back Office website acts as the central hub that allows you to manage your entire paring operation, deployed services and more.',
    infoThree:
      'It included customized features that manage employee payroll, garage revenue, device tracking and customer support',
  },
  sectionTwo: {
    header: 'BACK OFFICE FEATURE HIGHLIGHTS',
    data: [
      {
        header: 'GARAGE ANALYTICS',
        info:
          'Understand how your garages perform with various analytical features such as Garage Index, Efficiency and Customer Wait Time.',
      },
      {
        header: 'BOARD AND SPECIAL RATES',
        info:
          'Manage garage board & special rates, offer discounts and issue coupons. Compare the effects of changes in rate over time and more.',
      },
      {
        header: 'EMPLOYEE PAYROLL',
        info:
          'Manage employee payroll across garages and check who is currently working at the garage. Access payroll history and onboard new employees.',
      },
      {
        header: 'GARAGE POS SUPPORT',
        info:
          'Offer support to garage operators in resolving POS issues or customer conflicts. Monitor garage transient and monthly account activities.',
      },
      {
        header: 'RESERVATION SUPPORT',
        info:
          'Manage reservations across all garages through multiple vendors in a single place.',
      },
      {
        header: 'COMPREHENSIVE REPORTS',
        info:
          'Over 100 reports ranging from accounting to ticket information to satisfy every need of a parking garage operators, supervisors and stakeholders.',
      },
      {
        header: 'REVENUE & ACCOUNTING',
        info:
          'Monitor garage cash flow, bank account transactions, online transactions and mail invoices.',
      },
      {
        header: 'CUSTOMER SERVICE',
        info:
          'Deliver the best in class customer service with our messaging and contact management system. Onboard new customers seamlessly to your monthly accounts list.',
      },
    ],
  },
};
