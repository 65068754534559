import { createGlobalStyle } from 'styled-components';
import { theme } from './common/common';

export const GlobalStyles = createGlobalStyle`
  body,
  span,
  label,
  input,
  button,
  text,
  textarea {
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    color: ${theme.colorBlack};
    line-height: 1.3;
  }

  body {
    margin: 0;
  }
  * {
    box-sizing: border-box;
  }
`;
