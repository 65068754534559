import * as React from 'react';
import { VmContainer } from './Container.styles';
import { ContainerProps } from './Container.types';

export const Container = (props: ContainerProps) => {
  const {
    background,
    children,
    image,
    className,
    backgroundShadeColor,
    flexDirection = 'column',
    flexDirectionMobile,
  } = props;
  return (
    <VmContainer
      children={children}
      background={background}
      image={image}
      className={className}
      backgroundShadeColor={backgroundShadeColor}
      flexDirection={flexDirection}
      flexDirectionMobile={flexDirectionMobile}
    />
  );
};
