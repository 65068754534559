import devBgSub from './../../common/assets/developer/devBgSub.png';
import bgMain from './../../common/assets/pos/bgMain.png';
import accountCard from './../../common/assets/developer/accountCard.png';
import bank from './../../common/assets/developer/bank.png';
import barChart from './../../common/assets/developer/barChart.png';
import newspaper from './../../common/assets/developer/newspaper.png';
import oauth from './../../common/assets/developer/oauth.png';
import parking from './../../common/assets/developer/parking.png';
import pieChart from './../../common/assets/developer/pieChart.png';
import restful from './../../common/assets/developer/restful.png';
import swagger from './../../common/assets/developer/swagger.png';
import swaggerInterface from './../../common/assets/developer/swaggerInterface.png';

export const developer = {
  mainBackground: bgMain,
  sectionOne: {
    header: 'DEVELOPERS',
    background: devBgSub,
    infoOne: 'Build your business through us.',
  },
  sectionTwo: {
    header: 'INTEGRATIONS',
    infoText:
      'Valet Manager is the only parking management platform that provides full platform integration for our development partners.',
    data: [
      {
        image: parking,
        header: 'Reserve Parking Spaces',
        text:
          'Reserve parking spaces for your customers using our platform via our affiliate program.',
      },
      {
        image: barChart,
        header: 'Track Revenue',
        text:
          'Provide accounting services to garage operators and owners leveraging our unique reporting and audit platform.',
      },
      {
        image: pieChart,
        header: 'Performance Indicators',
        text:
          'Provide key insights in the performance of garage operations, employee efficiency, and revenue collection.',
      },
      {
        image: bank,
        header: 'Payroll',
        text:
          'Utilize our time tracking system to provide integrated payroll and tax services.',
      },
      {
        image: newspaper,
        header: 'Billing',
        text:
          'Integrate your billing service into our monthly account management platform.',
      },
      {
        image: accountCard,
        header: 'HR',
        text: 'Tie your HR solution into our employee management service.',
      },
    ],
  },
  sectionThree: {
    header: 'DEVELOPER STACK',
    data: [
      {
        image: swagger,
        title: 'swagger.io',
      },
      {
        image: oauth,
        title: 'OAuth2',
      },
      {
        image: restful,
        title: 'RESTful API',
      },
    ],
  },
  sectionFour: {
    image: swaggerInterface,
    btnText: 'Integrate with our Platform',
  },
};
