import cameraStand from '../../common/assets/lpr/cameraStand.png';
import lprDots from '../../common/assets/lpr/lprDots.png';
import lprActivity from '../../common/assets/lpr/lprActivity.png';
import skewBig from '../../common/assets/lpr/skewBig.png';

export const lpr = {
  sectionOne: {
    header: 'LICENSE PLATE RECOGNITION',
    infoText: 'Our robust LPR system can be deployed in any garage setting',
    background: cameraStand,
    image: lprActivity,
    imageOneText:
      'Monitor garage activity & understand wait time and the vehicle flow in your garage',
    imageTwoText:
      'Improve your ticket auditing process with our algorithms identifying discrepancies',
  },
  sectionTwo: {
    header: 'ACTIVITY',
    background: skewBig,
    image: lprDots,
    infoTextOne:
      'Our LPR system is capable of recognizing the vehicle and determine if the vehicle user has a monthly account or not.',
    infoTextTwo:
      'The LPR system also assists in ticketing and tracks real time rate for the vehicles and their actual entry and exit time from the garage',
  },
  sectionThree: {
    header: 'AUDIT',
    infoText:
      'Understand and prevent revenue loss by using our smart LPR system. Our system monitors the vehicles in the garage and identifies discrepancies between actual entry/exit time vs the time issued in the ticket',
  },
};
