import * as React from 'react';
import {
  VmSolutions,
  VmFlex,
  VmBigHeader,
  VmInnerBox,
  VmTransformation,
  VmHeaderContent,
  VmExplore,
  VmPublicContainer,
  VmPlates,
  VmPlatesContainer,
  VmLpr,
  VmInfoContent,
  VmMonthly,
  VmMonthlyText,
  VmMonthlyBox,
  VmComprehensive,
  VmGarageHeader,
  VmBigHeaderMain,
  VmClientService,
} from './Solutions.styles';
import {
  headerOne,
  headerOneData,
  lpr,
  parking,
  monthly,
  backOffice,
  comprehensive,
  manageGarage,
  footerData,
  clientService,
  clientServiceData,
} from './Solutions.data';
import { withRouter } from 'react-router';
import macLarge from '../../common/assets/mac_large.png';
import skewLeft from '../../common/assets/skewLeft.png';
import skewRight from '../../common/assets/skewRight.png';
import phoneMap from '../../common/assets/reservations/mobReservation.png';
import draw from '../../common/assets/draw.jpg';
import { theme } from '../../common/common';
import { SolutionProps } from './Solutions.types';
import { Footer, LearnMoreButton } from '../../components';

const SolutionsPage = (props: SolutionProps) => {
  const { history } = props;
  React.useEffect(() => {
    const element = document.getElementById('home-page');
    if (element) {
      element.scrollTo(0, 0);
    }
  }, []);

  const renderTransform = () => {
    return (
      <VmTransformation>
        <VmFlex>
          <VmInnerBox>
            <VmBigHeaderMain color={theme.colorWhite}>
              {headerOne}
            </VmBigHeaderMain>
            <VmHeaderContent>{headerOneData}</VmHeaderContent>
          </VmInnerBox>
        </VmFlex>
        <VmExplore>EXPLORE</VmExplore>
      </VmTransformation>
    );
  };

  const renderLpr = () => {
    const renderPlate = (value: any, index: number) => {
      return (
        <VmPlates key={`plate-${index}`}>
          <img src={value.img}></img>
          <div>{value.data}</div>
        </VmPlates>
      );
    };

    const onLprClick = () => {
      history.push('/lpr');
    };

    return (
      <VmPublicContainer className={'shrink'}>
        <VmLpr>
          <VmBigHeader className="lpr" color={theme.colorWhite}>
            {lpr.bigHeader}
          </VmBigHeader>
          <VmInfoContent className="lpr">{lpr.infoText}</VmInfoContent>
          <LearnMoreButton
            background={theme.colorBlack}
            color={theme.colorWhite}
            onClick={onLprClick}
            className={'right-bottom'}
          />
        </VmLpr>
        <VmPlatesContainer>{lpr.plates.map(renderPlate)}</VmPlatesContainer>
      </VmPublicContainer>
    );
  };

  const onMonthlyClick = () => history.push('/monthly');

  const renderMonthlyPortal = () => {
    return (
      <VmPublicContainer className="cover" image={skewLeft}>
        <VmMonthly width={'60%'}>
          <VmBigHeader color={theme.colorWhite}>
            {monthly.bigHeader}
          </VmBigHeader>
          <VmInfoContent width={'60%'}>{monthly.infoText}</VmInfoContent>
          <LearnMoreButton
            background={theme.colorPrimary}
            color={theme.colorWhite}
            onClick={onMonthlyClick}
            className={'right-bottom'}
          />
        </VmMonthly>
        <VmMonthly width={'40%'}>
          <VmMonthlyBox>
            <VmMonthlyText>
              <span>{monthly.innertext1}</span>
              <span className="bold">{monthly.innertext2}</span>
            </VmMonthlyText>
            <img src={monthly.image} />
          </VmMonthlyBox>
        </VmMonthly>
      </VmPublicContainer>
    );
  };

  const onReservationClick = () => history.push('/reservations');

  const renderParking = () => {
    return (
      <VmPublicContainer className="cover gradient" image={skewRight}>
        <VmMonthly width={'60%'}>
          <VmBigHeader color={theme.colorWhite}>
            {parking.bigHeader}
          </VmBigHeader>
          <VmInfoContent width={'60%'}>{parking.infoText}</VmInfoContent>
          <LearnMoreButton
            background={theme.colorPrimary}
            color={theme.colorWhite}
            onClick={onReservationClick}
            className={'right-bottom'}
          />
        </VmMonthly>
        <VmMonthly width={'40%'}>
          <img className="phone" src={phoneMap} />
        </VmMonthly>
      </VmPublicContainer>
    );
  };

  const onBackOfficeClick = () => history.push('/backoffice');

  const renderBackOffice = () => {
    return (
      <VmPublicContainer background={theme.colorWhite} image={'none'}>
        <VmMonthly width={'65%'}>
          <VmBigHeader color={theme.colorBlack}>
            {backOffice.bigHeader}
          </VmBigHeader>
          <VmInfoContent color={theme.colorBlack} width={'60%'}>
            {backOffice.infoText}
          </VmInfoContent>
          <LearnMoreButton
            background={theme.colorWhite}
            color={theme.colorBlack}
            onClick={onBackOfficeClick}
            className={'right-bottom'}
          />
        </VmMonthly>
        <VmMonthly width={'35%'}>
          <img className="phone" src={macLarge} />
        </VmMonthly>
      </VmPublicContainer>
    );
  };

  const renderComprehensive = () => {
    return (
      <VmComprehensive>
        <VmMonthly className="padd" width={'65%'}>
          <VmBigHeader color={theme.colorBlack}>
            {comprehensive.bigHeader}
          </VmBigHeader>
          <VmInfoContent color={theme.colorBlack} width={'60%'}>
            {comprehensive.infoText}
          </VmInfoContent>
        </VmMonthly>
        <VmMonthly width={'35%'}>
          <img className="phone laptop" src={draw} />
        </VmMonthly>
      </VmComprehensive>
    );
  };

  const onPosClick = () => history.push('/pos');

  const renderManageGarage = () => {
    return (
      <VmPublicContainer
        background={theme.colorBlack}
        className="reverse"
        image={'none'}
      >
        <VmMonthly className="pos" width={'60%'}>
          <img className="phone pos" src={manageGarage.image} />
        </VmMonthly>
        <VmMonthly width={'40%'}>
          <div>
            <VmGarageHeader color={theme.colorWhite}>
              {manageGarage.bigHeader}
            </VmGarageHeader>
            <VmGarageHeader className="bold" color={theme.colorWhite}>
              {manageGarage.bigHeader2}
            </VmGarageHeader>
            <VmGarageHeader color={theme.colorWhite}>
              {manageGarage.bigHeader3}
            </VmGarageHeader>
          </div>
          <LearnMoreButton
            background={theme.colorBlack}
            color={theme.colorWhite}
            onClick={onPosClick}
            className={'right-bottom'}
          />
        </VmMonthly>
      </VmPublicContainer>
    );
  };

  const renderClientService = () => {
    return (
      <VmClientService>
        <VmFlex>
          <VmInnerBox>
            <VmBigHeaderMain color={theme.colorWhite}>
              {clientService}
            </VmBigHeaderMain>
            <VmHeaderContent>{clientServiceData}</VmHeaderContent>
          </VmInnerBox>
        </VmFlex>
      </VmClientService>
    );
  };

  const renderFooter = () => {
    return <Footer history={props.history} />;
  };

  return (
    <VmSolutions>
      {renderTransform()}
      {renderLpr()}
      {renderMonthlyPortal()}
      {renderParking()}
      {renderBackOffice()}
      {renderComprehensive()}
      {renderManageGarage()}
      {renderClientService()}
      {renderFooter()}
    </VmSolutions>
  );
};

export const Solutions = withRouter(SolutionsPage);
