import dollar from '../../common/assets/dollar.svg';
import chip from '../../common/assets/chip.svg';
import cloud from '../../common/assets/cloud.svg';
import increase from '../../common/assets/increase.svg';
import settings from '../../common/assets/settings.svg';
import tablet from '../../common/assets/tablet.svg';
import plate1 from '../../common/assets/plate1.png';
import plate2 from '../../common/assets/plate2.png';
import plate3 from '../../common/assets/plate3.png';
import plate4 from '../../common/assets/plate4.png';
import calender from '../../common/assets/calendar.jpg';
import ipadLarge from '../../common/assets/ipad_large.png';

export const headerOne = 'Transform Your Parking Operations';
export const headerOneData =
  'Valet Manager offers a complete end-to-end platform for your parking operations unlike other systems which are fragmented and expensive.';

export const ourSolutionsData = {
  header: 'Our Solutions',
  subHeader:
    'Valet Manager is the first cloud-based parking management platform that meets all of your operational needs.',
  infoArray: [
    {
      image: chip,
      title: 'Integrated Architecture',
      info:
        'Say goodbye to disparate systems required to manage operations of your garage. Our platform integrates transient, monthly, customer, mobile, and hotel operations making it easy for you to serve your customers and eliminate theft.',
    },
    {
      image: increase,
      title: 'Comprehensive Reporting',
      info:
        'Our integrated reporting platform provides a holistic view of your operations providing numerous customizable reports and real-time analytics that drive deep insights. Furthermore our reporting stands ready to take on the most detailed audit requirements.',
    },
    {
      image: tablet,
      title: 'Commercial Grade Hardware',
      info:
        'Our systems are based on affordable commercial grade and built to last repetitive use from demanding parking service environments without the burden of large capital expenditures.',
    },
    {
      image: settings,
      title: 'Third Party integrations',
      info:
        'We provide integrations with various existing coupon vendors, validation services and accounting backend systems.',
    },
    {
      image: cloud,
      title: 'Remote Operations Management',
      info:
        'Operations teams can view in real-time dashboards showing various metrics about their garages, manage employee hours, customer information, administer coupons and rates, as well as a host of other management functions.',
    },
    {
      image: dollar,
      title: 'Low Cost',
      info:
        'We have designed our systems to be efficient using advances in cloud technology to bring significant cost savings to our customers.',
    },
  ],
};

export const rethinkHeader = 'We Rethought Everything';
export const rethinkHeaderText = 'Get instant efficiencies across the board';
export const intergrated = 'Integrated Architecture';
export const integratedTextPartOne =
  'Having disparate or siloed systems to service different types of customers lead to inefficiencies in operations especially in revenue reconciliation.';
export const integratedTextPartTwo =
  'Our integrated platform serves your transient, monthly, self-park and hotel customers allowing you to spend more time with your customers instead of reconciling data';

export const comprehensiveReport = 'Comprehensive Reporting';
export const comGradeHard = 'Commercial Grade Hardware';

export const thirdParty = 'Third Party Integration';
export const thirdPartyOne =
  'Our platform is delivered on industry-leading components that promise year over year dependability even in the most demanding environments.';
export const thirdPartyTwo =
  'Our hardware provides the ability to easily updated and configured incorporating the latest technologies including mobile integration and EVM based credit card processing';

export const remoteOperation = 'Remote Operations Management';
export const remoteOperationText =
  'Remotely manage your employees, garages, rate cards, and monthly customer information. For example take advantage of our cloud infrastructure to make system configuration changes such as rate cards instantly or use our ad platform to remotely manage advertisements to be shown in garage. We aim to provide owners and operators the tools to administer their properties in real-time without the bottlenecks of relying on third parties to make changes.';

export const clientService = 'Client Service is everything to us';
export const clientServiceData =
  'Unlike other transportation technology companies, we treat our customers like clients. We take pride in being a true partner and trusted advisor capable of understanding specific issues and delivering measureable results quickly. Our client base of large regional, national, and international parking operators consistently attest to our flawless execution and superior service.';

export const requestHeader = 'Request a Call';
export const requestText =
  'Please fill out the form we will get back to you in 24 hours';

export const comprehensiveReportRead =
  'At Valet Manager we understand the multitude of reports that are required to effectively manage operations and alleviate all audit needs. We have completely automated a customizable report set and removed the hours of manual paperwork previously required to manage operations. Our systems have been proven to eliminate theft and instill confidence with garage owners and operators.';
export const comprehensiveReportReadTwo =
  ' We have the most comprehensive reporting platform that goes well beyond anything available today in the industry.';

export const comGradeHardRead =
  'Our platform is delivered on industry-leading components that promise year over year dependability even in the most demanding environments.';
export const comGradeHardReadTwo =
  ' Our hardware provides the ability to easily updated and configured incorporating the latest technologies including mobile integration and EVM based credit card processing';

export const lpr = {
  bigHeader: 'LICENSE PLATE RECOGNITION',
  infoText:
    'Reduce wait time and improve your garage experience, while monitoring vehicle flow in and out of your garages.',
  plates: [
    {
      img: plate1,
      data: 'ABC 1234',
    },
    {
      img: plate2,
      data: '6GDG486',
    },
    {
      img: plate3,
      data: 'Y0E566',
    },
    {
      img: plate4,
      data: 'CD 80519',
    },
  ],
};

export const monthly = {
  bigHeader: 'MONTHLY PORTAL',
  infoText: 'Radically improve the experience of your Monthly Account Holders',
  innertext1: 'BEST SOLUTION FOR MANAGING',
  innertext2: ' MONTHLY ACCOUNTS',
  image: calender,
};

export const parking = {
  bigHeader: 'RESERVE PARKING SPOT',
  infoText: 'Bring in more customers with our web and mobile friendly website',
};

export const backOffice = {
  bigHeader: 'EXTENSIVE BACK OFFICE SUPPORT',
  infoText:
    'Manage Parking Rates, Employee Payroll, Garage Activity & Operations, Analytics, Cash Drops, Messaging & Support and Lot more.',
};

export const comprehensive = {
  bigHeader: 'COMPREHENSIVE REPORTS',
  infoText:
    'Understand your garage operations better, compare rates and their effectiveness, efficiency and activity, track revenue and cash flows and manage employee payrolls.',
};

export const manageGarage = {
  bigHeader: 'MANAGE GARAGE OPERATIONS THROUGH SMART ',
  bigHeader2: 'POINT OF SALE ',
  bigHeader3: 'DEVICES',
  image: ipadLarge,
};

export const footerData = [
  {
    header: 'BACK OFFICE MANAGEMENT',
    data: ['Payroll', 'Analytics', 'Managing Rates', 'Reports', 'more...'],
  },
  {
    header: 'GARAGE MANAGEMENT',
    data: [
      'POS',
      'License Plate Recognition',
      'Gate Arm',
      'Touchless Ticketing',
      'more...',
    ],
  },
  {
    header: 'PUBLIC PORTALS',
    data: ['Reservations', 'Monthly Accounts', 'Company Websites', 'more...'],
  },
];
