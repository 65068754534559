import * as React from 'react';
import { theme } from '../../common/common';
import { Container, Footer } from '../../components';
import { lpr } from './LprPage.data';
import {
  VmActivityAudit,
  VmAudit,
  VmAuditInner,
  VmBigHeader,
  VmInfoContent,
  VmLpr,
  VmLprImage,
  VmLprImageBg,
} from './styles';

export const LprPage = (props: any) => {
  React.useEffect(() => {
    const element = document.getElementById('home-page');
    if (element) {
      element.scrollTo(0, 0);
    }
  }, []);

  const renderLicensePlateRecognition = () => {
    return (
      <VmLprImageBg>
        <VmBigHeader className="big-header" color={theme.colorWhite}>
          {lpr.sectionOne.header}
        </VmBigHeader>
        <VmInfoContent className="big-info" width={'60%'}>
          {lpr.sectionOne.infoText}
        </VmInfoContent>
        <VmLprImage className="fit-content">
          <img src={lpr.sectionOne.image} />
          <VmActivityAudit>
            <VmInfoContent
              className="padding"
              align={'center'}
              width={'30%'}
              alignMobile={'left'}
            >
              {lpr.sectionOne.imageOneText}
            </VmInfoContent>
            <VmInfoContent align={'center'} width={'30%'} alignMobile={'right'}>
              {lpr.sectionOne.imageTwoText}
            </VmInfoContent>
          </VmActivityAudit>
        </VmLprImage>
      </VmLprImageBg>
    );
  };

  const renderActivityAndAudit = () => {
    return (
      <Container background={'none'} flexDirectionMobile={'column'}>
        <VmBigHeader color={theme.colorWhite}>
          {lpr.sectionTwo.header}
        </VmBigHeader>
        <VmInfoContent width={'95%'}>
          {lpr.sectionTwo.infoTextOne}
        </VmInfoContent>
        <span style={{ paddingBottom: '2rem' }} />
        <VmInfoContent width={'95%'}>
          {lpr.sectionTwo.infoTextTwo}
        </VmInfoContent>
        <VmLprImage>
          <img src={lpr.sectionTwo.image} />
        </VmLprImage>
        <VmAudit>
          <VmBigHeader color={theme.colorWhite}>
            {lpr.sectionThree.header}
          </VmBigHeader>
          <VmActivityAudit className="width">
            <VmAuditInner>Charged Rate</VmAuditInner>
            <VmAuditInner>VS</VmAuditInner>
            <VmAuditInner>Actual Rate</VmAuditInner>
          </VmActivityAudit>
          <VmInfoContent width={'100%'}>
            {lpr.sectionThree.infoText}
          </VmInfoContent>
        </VmAudit>
      </Container>
    );
  };

  const renderFooter = () => {
    return <Footer history={props.history} />;
  };

  return (
    <VmLpr>
      {renderLicensePlateRecognition()}
      {renderActivityAndAudit()}
      {renderFooter()}
    </VmLpr>
  );
};
