import styled, { css } from 'styled-components';
import { theme } from '../../common/common';
import { reservation } from './Reservations.data';

interface BigHeaderProps {
  color?: string;
}

interface InfoCssProps {
  width?: string;
  color?: string;
  align?: string;
  alignMobile?: string;
}

export const VmReservations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${reservation.mainBackground});
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  background-position: center;
  .center-div {
    max-width: 100rem;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    background-size: cover;
    background-position: center;
    background-position-x: 51%;
  }
`;

export const VmReservationImageBg = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem;
  position: relative;
  min-height: calc(100vh - 6rem);
  z-index: 1;
  max-width: 100rem;
  margin: 0 auto;
  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(${reservation.sectionOne.background});
    opacity: 0.3;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: 768px) {
    min-height: calc(100vh - 4rem);
    justify-content: space-around;
    flex-direction: column;
    padding: 1rem;
    &:after {
      background-position: center;
    }
  }
`;

export const VmBigHeader = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
  padding-bottom: 1rem;
  &.pad-left {
    padding-left: 4rem;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
    &.pad-left {
      padding-left: 1rem;
    }
    &.big-header {
      font-size: 2.8rem;
    }
    &.lpr {
      text-align: center;
    }
  }
`;

export const VmInfoContent = styled.div`
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: ${(props: InfoCssProps) =>
    props.color ? props.color : theme.colorWhite};
  width: ${(props: InfoCssProps) => (props.width ? props.width : '60%')};
  text-align: ${(props: InfoCssProps) => (props.align ? props.align : 'left')};
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
    ${(props: InfoCssProps) =>
      props.alignMobile &&
      css`
        text-align: ${props.alignMobile};
      `}
    &.lpr {
      text-align: center;
    }
    &.big-info {
      font-size: 1.4rem;
    }
  }
`;

export const FlexOne = styled.div`
  width: 40%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FlexTwo = styled.div`
  width: 60%;
  &.pad-left {
    padding-left: 3rem;
  }
  ol {
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    &.pad-left {
      padding-left: 0rem;
    }
    ol {
      font-size: 1.3rem;
    }
  }
`;

export const VmReservationsImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    padding-bottom: 2rem;
    max-height: 100%;
    object-fit: cover;
    width: 95%;
    height: 100%;
  }
  @media (max-width: 768px) {
    padding: 1rem 0;
    img {
      padding-bottom: 0rem;
      width: 100%;
    }
  }
`;

export const VmResDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
