import * as React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router';

import {
  Solutions,
  MonthlyPage,
  AboutUsPage,
  LprPage,
  RequestDemoPage,
  BackOfficePage,
  DeveloperPage,
  ReservationsPage,
  PosPage,
} from '../index';

const RoutesInternal = () => {
  return (
    <Switch>
      <Route path={'/solutions'} component={Solutions} />
      <Route path={'/about'} component={AboutUsPage} />
      <Route path={'/lpr'} component={LprPage} />
      <Route path={'/monthly'} component={MonthlyPage} />
      <Route path={'/backoffice'} component={BackOfficePage} />
      <Route path={'/reservations'} component={ReservationsPage} />
      <Route path={'/pos'} component={PosPage} />
      <Route path={'/developer'} component={DeveloperPage} />
      <Redirect path="/" to="/solutions" />
    </Switch>
  );
};

export const Routes = withRouter(RoutesInternal);
