import * as React from 'react';
import {
  VmAppHeader,
  LogoImage,
  VmList,
  VmListItem,
  VmMenu,
  VmOverLay,
} from './Header.styles';
import vmlogo from './../../common/assets/vmlogo.png';
import { MenuListProps, AppHeaderProps } from './Header.types';
import { cloneDeep, debounce } from 'lodash';
import { withRouter } from 'react-router';
import { theme } from '../../common/common';

const AppHeader = (props: AppHeaderProps) => {
  const { history } = props;
  const getColor = () => {
    switch (history.location.pathname) {
      case '/lpr':
        return theme.colorGreyDarker;
      default:
        return theme.colorGreyDarker;
    }
  };
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isActive, setActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handleResize = debounce(() => setWidth(window.innerWidth), 100);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  const isItemSelected = (requiredPath: string) =>
    history.location.pathname === `${requiredPath}`;

  const menuList: MenuListProps[] = [
    {
      isSelected: isItemSelected('/solutions'),
      onClick: () => history.push('/solutions'),
      title: 'HOME',
    },
    {
      isSelected: false,
      onClick: () =>
        window.open('https://backoffice.valetmanager.com', '_blank'),
      title: 'BACK OFFICE',
    },
    {
      isSelected: false,
      onClick: () => window.open('https://hotel.valet-manager.com', '_blank'),
      title: 'HOTEL',
    },
    {
      isSelected: isItemSelected('/contact'),
      onClick: () => window.FreshworksWidget('open'),
      title: 'CONTACT',
    },
    {
      isSelected: isItemSelected('/developer'),
      onClick: () => history.push('/developer'),
      title: 'DEVELOPER',
    },
  ];

  const onItemClick = (item: MenuListProps) => {
    item.onClick();
    setActive(false);
  };

  const onIconClick = () => {
    setActive(!isActive);
  };

  const renderOptions = (value: MenuListProps, idx: number) => {
    return (
      <div key={`item-${idx}`}>
        <VmListItem
          active={value.isSelected}
          onClick={onItemClick.bind(null, value)}
        >
          {value.title}
        </VmListItem>
      </div>
    );
  };

  const renderMenuList = () => {
    const menuOptions = cloneDeep(menuList);
    return (
      <VmList isActive={isActive}>{menuOptions.map(renderOptions)}</VmList>
    );
  };

  const onLogoClick = () => history.push('/solutions');

  return (
    <VmAppHeader color={getColor()}>
      <LogoImage src={vmlogo} onClick={onLogoClick} />

      <VmOverLay isActive={isActive} onClick={onIconClick} />
      <VmMenu onClick={onIconClick} />
      {renderMenuList()}
    </VmAppHeader>
  );
};

export const AppHeaderConnected = withRouter(AppHeader);
