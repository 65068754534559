export const theme = {
  colorPrimary: '#073847',
  colorWhite: '#FFFFFF',
  colorPrimaryLight: '#19A8BC',
  colorCreamWhite: '#F5FBFB',
  colorLightBlue: '#BFE7F2',
  colorBlack: '#0A0C0C',
  colorDarkBlue: '#10A2CE',
  colorGrey: '#D7E3E3',
  colorGreyDarker: '#2D2D2F',
  colorGreyDark: '#636366',
  colorBlue: '#027591',
  colorPureBlack: '#000000',
};
