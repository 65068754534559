import * as React from 'react';
import { PageBody, AppHeaderConnected } from '../../components';
import { Routes } from './routes';
import { Body } from './styles';
import { withRouter } from 'react-router';

export const Home = () => {
  return (
    <PageBody>
      <AppHeaderConnected />
      <Body id="home-page">
        <Routes />
      </Body>
    </PageBody>
  );
};

export const HomePage = withRouter(Home);
