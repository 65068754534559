import bgMain from './../../common/assets/pos/bgMain.png';
import bgSub from './../../common/assets/pos/bgSub.png';
import posDevice from './../../common/assets/pos/posDevice.png';
import posIpad from './../../common/assets/pos/posIpad.png';

export const pos = {
  mainBackground: bgMain,
  sectionOne: {
    header: 'POINT OF SALE',
    background: bgSub,
    image: posDevice,
    infoOne:
      'A touch screen point of sale device that efficiently manages all garage activities.',
    infoTwo:
      'Easy to deploy and Easy to use, our POS system is designed to improve the customer experience in the garage and streamline your garage operations.',
  },
  sectionTwo: {
    header: 'A COMPANION FOR EFFICIENT GARAGE OPERATIONS',
    image: posIpad,
  },
  sectionThree: [
    {
      header: 'ISSUE TICKETS',
      data:
        'Our POS is capable of issuing and reading tickets with the help of an integrated printer and scanner.',
    },
    {
      header: 'LPR INTEGRATION',
      data:
        'The POS works seamlessly with the LPR system to auto populate the vehicle details and drastically reduces time taken to issue tickets.',
    },
    {
      header: 'REVENUE MANAGEMENT',
      data:
        'The POS is equipped with a credit card terminal and the system is also capable og tracking cash payments and online transactions.',
    },
    {
      header: 'GARAGE REPORTS',
      data:
        'A garage supervisor can generate several reports to monitor garage activities for a shift through several reports.',
    },
  ],
};
