import * as React from 'react';
import { LearnMoreButtonProps } from './LearnMoreButton.types';
import { VmLearnMoreBtn } from './styles';

export const LearnMoreButton = (props: LearnMoreButtonProps) => {
  const { background, color, onClick, className } = props;
  return (
    <VmLearnMoreBtn
      background={background}
      className={className}
      color={color}
      onClick={onClick}
      children={'LEARN MORE'}
    />
  );
};
