import styled from 'styled-components';
import { theme } from '../../common/common';
import { LearnMoreButtonCssProps } from './LearnMoreButton.types';

export const VmLearnMoreBtn = styled.div`
  padding: 1rem 1.5rem;
  border: 1px solid ${(props: LearnMoreButtonCssProps) => props.color};
  color: ${(props: LearnMoreButtonCssProps) => props.color};
  background: ${(props: LearnMoreButtonCssProps) => props.background};
  cursor: pointer;
  text-align: center;
  border-radius: 0.6rem;
  width: 10rem;
  margin-top: 1rem;
`;
