import styled, { css } from 'styled-components';
import { theme } from '../../common/common';
import parkedCars from '../../common/assets/parkedCars.jpg';
import cameraLens from '../../common/assets/cameraLens.png';
import lights from '../../common/assets/lights.png';
import {
  ContainerProps,
  BigHeaderProps,
  MediumTitleProps,
  InfoCssProps,
  PublicContainerCssProps,
} from './Solutions.types';
import { motion } from 'framer-motion';

export const VmSolutions = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  margin: 0 auto;
`;

export const VmTransformation = styled.div`
  background-image: url(${parkedCars});
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  background-position: center;
  height: calc(100vh - 6rem);
  position: relative;
  box-shadow: inset 0 0 0 1000px rgba(7, 56, 71, 0.75);
  @media (max-width: 768px) {
    height: calc(100vh - 4rem);
    text-align: left;
    background-size: cover;
  }
`;

export const VmClientService = styled.div`
  background-image: url(${lights});
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 101vw;
  justify-content: center;
  background-position-x: -1rem;
  padding: 4rem 0;
  position: relative;
  @media (max-width: 768px) {
    text-align: left;
    background-size: cover;
  }
`;

export const VmExplore = styled.div`
  font-size: 1.5rem;
  color: ${theme.colorWhite};
  position: absolute;
  z-index: 1;
  bottom: 1rem;
  left: 4rem;
  @media (max-width: 768px) {
    left: 1rem;
  }
`;

export const VmPublicContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 4rem;
  background-image: url(${(props: PublicContainerCssProps) =>
    props.image ? props.image : cameraLens});
  background-repeat: no-repeat;
  background-color: ${(props: PublicContainerCssProps) =>
    props.background ? props.background : theme.colorPureBlack};
  position: relative;
  &.shrink {
    background-size: 75%;
  }
  &.cover {
    background-size: cover;
    background-position: bottom;
  }
  &.gradient {
    background-position: initial;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(${theme.colorPrimary}, ${theme.colorWhite});
      opacity: 0.5;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2rem;
    &.shrink {
      background-position: bottom left;
      background-size: 100%;
    }
    &.reverse {
      flex-direction: column-reverse;
    }
  }
`;

export const VmPlatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 15rem;
  }
`;

export const VmPlates = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  img {
    width: 10rem;
    height: 3rem;
    padding-right: 2rem;
  }
  div {
    color: ${theme.colorWhite};
  }
  @media (max-width: 768px) {
    flex: 1 1 39%;
    padding: 1rem;
    flex-direction: column;
    margin: 1rem;
    background: ${theme.colorPrimary};
    border-radius: 1rem;
    img {
      padding: 0 0 1rem 0;
      width: 100%;
    }
  }
`;

export const VmLpr = styled.div`
  display: flex;
  flex-direction: column;
  width: 79%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const VmMonthly = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props: InfoCssProps) => (props.width ? props.width : '50%')};
  .phone {
    max-width: 100%;
  }
  &.padd {
    padding: 2rem;
  }
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
    .phone {
      padding: 2rem 0;
    }
    .laptop {
      padding: 0;
    }
    .pos {
      max-width: none;
      width: 120%;
      height: 120%;
      position: relative;
      right: -10%;
    }
    &.pos {
      padding: 2rem 0;
    }
  }
`;

export const VmInfoContent = styled.div`
  font-size: 1.5rem;
  color: ${(props: InfoCssProps) =>
    props.color ? props.color : theme.colorWhite};
  width: ${(props: InfoCssProps) => (props.width ? props.width : '60%')};
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
    &.lpr {
      text-align: center;
    }
  }
`;

export const VmMonthlyBox = styled.div`
  background: ${theme.colorWhite};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  img {
    max-width: 100%;
    opacity: 0.7;
  }
  @media (max-width: 768px) {
    margin: 2rem 0;
  }
`;

export const VmMonthlyText = styled.div`
  width: 80%;
  padding: 1rem;
  span {
    font-size: 1.4rem;
  }
  .bold {
    font-weight: 600;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const VmComprehensive = styled.div`
  margin: 1rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 8px 2px ${theme.colorGrey};
  display: flex;
  background: ${theme.colorWhite};
  flex-wrap: nowrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const VmGarageHeader = styled.span`
  font-size: 2.5rem;
  &.bold {
    font-weight: 600;
  }
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const VmFlex = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 80%;
  align-items: center;
  justify-content: center;
  position: relative;
  &.inner-padding {
    padding: 3rem 0;
  }
  &.inner-padding-more {
    padding: 5rem 0 5rem 5rem;
  }
`;

export const VmBigHeader = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    &.lpr {
      text-align: center;
    }
  }
`;

export const VmBigHeaderMain = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const VmHeaderContent = styled.div`
  font-size: 1.5rem;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: left;
  }
`;

export const VmMacImage = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
`;

export const VmInnerBox = styled.div`
  padding: 0 4rem;
  color: ${theme.colorWhite};
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const VmSubHeader = styled.div`
  color: ${theme.colorWhite};
`;

export const VmIPad = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 40%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const VmIphone = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 40%;
  position: absolute;
  bottom: 0;
  right: 6%;
`;

export const VMImageContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 50%;
  &.client-image {
    padding: 0 7rem;
  }
`;

export const VmInfoBox = styled.div`
  padding: 1rem 3rem;
  width: 33%;
  color: ${theme.colorWhite};
`;

export const VmInfoBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
`;

export const VmInfoImage = styled.img`
  padding-bottom: 1rem;
  height: 4rem;
`;

export const VmInfoSmallHeader = styled.div`
  font-weight: 600;
  padding-bottom: 0.5rem;
`;

export const VmRethink = styled.div`
  padding: 4rem 6rem;
  text-align: center;
`;

export const VmMediumTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 1rem;
  ${(props: MediumTitleProps) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
`;

export const VmWrapper = styled.div`
  margin: 0 1rem;
`;

export const VmReportContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-wrap: nowrap;
  p {
    color: ${theme.colorWhite};
  }
`;

export const VmImageReportContainer = styled.div`
  margin: 0 1rem;
  background: ${theme.colorCreamWhite};
  padding: 2rem 4rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const VmCompRead = styled.div`
  margin: 0 1rem;
  background: ${theme.colorPrimary};
  padding: 2rem 4rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const VmMediumImageTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 1rem;
  text-align: center;
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
`;

export const VmArchContainer = styled.div`
  margin: 5rem 1rem;
  position: relative;
`;

export const VmArchImage = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: -19%;
  left: 2%;
`;

export const VmArchImageContainer = styled.div`
  width: 50%;
  padding: 0 4rem;
  display: flex;
`;

export const VmRemoteContainer = styled.div`
  margin: 5rem 1rem;
  position: relative;
`;

export const VmRemoteImage = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: -19%;
`;

export const VmRemoteImageContainer = styled.div`
  width: 50%;
  padding: 0 4rem;
  display: flex;
`;

export const VmTextRight = styled.div`
  text-align: right;
`;

export const VmSubmitForm = styled.form``;

export const VmInput = styled.input`
  width: 100%;
  border: none;
  background: ${theme.colorWhite};
  border: 1px solid ${theme.colorGrey};
  padding: 0.5rem 0px 0.5rem 0.4rem;
  outline: none;
`;

export const VmTextArea = styled.textarea`
  width: 100%;
  border: none;
  background: ${theme.colorWhite};
  border: 1px solid ${theme.colorGrey};
  padding: 0.5rem 0px 0.5rem 0.4rem;
  outline: none;
  height: 6rem;
  resize: none;
`;

export const VmSubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  > input {
    width: 32%;
  }
`;

export const VmSumbitFormWrapper = styled.div`
  margin: 2rem;
`;

export const VmAnchor = styled.button`
  border: 1px solid ${theme.colorPrimaryLight};
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  color: ${theme.colorPrimaryLight};
  text-decoration: none;
  margin: 1rem 0;
  background: ${theme.colorWhite};
  outline: none;
  cursor: pointer;
  :hover {
    border: 1px solid ${theme.colorPrimary};
    color: ${theme.colorPrimary};
  }
`;

export const VmPlusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  > img {
    cursor: pointer;
    width: 3rem;
  }
  &.close {
    > img {
      cursor: pointer;
      width: 4.3rem;
    }
  }
`;

export const VmRedirectBtn = styled.div`
  margin: 4rem 0;
  color: ${theme.colorWhite};
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const VmSubmitButton = styled.input`
  background: ${theme.colorPrimary};
  outline: none;
  padding: 0.7rem 2.5rem;
  color: ${theme.colorWhite};
  font-weight: 500;
  border-radius: 0.2rem;
  border: none;
  cursor: pointer;
  :hover {
    background: ${theme.colorPrimaryLight};
  }
`;

export const VmButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
`;
