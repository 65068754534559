import styled, { css } from 'styled-components';
import { theme } from '../../common/common';
import { HeaderCssProps, MenuProps } from './Header.types';
import { Menu } from '@styled-icons/material/Menu';

export const VmAppHeader = styled.div`
  height: 6rem;
  display: flex;
  z-index: 10;
  background: ${(props: HeaderCssProps) =>
    props.color ? props.color : theme.colorGreyDark};
  position: sticky;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 0 3rem;
  @media (max-width: 768px) {
    height: 4rem;
    padding: 0 1rem;
    justify-content: space-between;
  }
`;

export const LogoImage = styled.img`
  width: 11rem;
  height: 2.5rem;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 9rem;
    height: 2rem;
  }
`;
interface ListCssProps {
  isActive?: boolean;
}
export const VmList = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    align-items: unset;
    transform: translateX(
      ${(props: ListCssProps) => (props.isActive ? '0' : '110%')}
    );
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    background: ${theme.colorBlack};
    z-index: 999;
    transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    flex-direction: column;
  }
`;

const activeCss = css`
  color: ${theme.colorPrimaryLight};
`;

export const VmListItem = styled.div`
  display: flex;
  margin: 1rem 1.5rem;
  font-weight: 600;
  color: ${theme.colorWhite};
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  padding-bottom: 0.2rem;
  ${(props: MenuProps) => props.active && activeCss};
`;

export const VmMenu = styled(Menu)`
  width: 3rem;
  height: 3rem;
  color: ${theme.colorWhite};
  padding: 0.2rem;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const VmOverLay = styled.div`
  ${(props: ListCssProps) =>
    props.isActive &&
    // tslint:disable-next-line: no-nested-template-literals
    css`
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 998;
      backdrop-filter: blur(4px);
      background: ${theme.colorGreyDark};
      opacity: 0.7;
      left: 0;
    `}
`;
