import styled, { css } from 'styled-components';
import { theme } from '../../common/common';
import { ContainerProps, BigHeaderProps } from './RequestDemo.types';

export const VmRequestDemo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const VmSubmitForm = styled.form``;

export const VmInput = styled.input`
  width: 100%;
  border: none;
  background: ${theme.colorWhite};
  border: 1px solid ${theme.colorGrey};
  padding: 0.5rem 0px 0.5rem 0.4rem;
  outline: none;
  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

export const VmTextArea = styled.textarea`
  width: 100%;
  border: none;
  background: ${theme.colorWhite};
  border: 1px solid ${theme.colorGrey};
  padding: 0.5rem 0px 0.5rem 0.4rem;
  outline: none;
  height: 6rem;
  resize: none;
`;

export const VmSubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  > input {
    width: 32%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    > input {
      width: 100%;
    }
  }
`;

export const VmSumbitFormWrapper = styled.div``;

export const VmContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: ${(props: ContainerProps) => props.padding};
  ${(props: ContainerProps) =>
    props.background &&
    css`
      background: ${props.background};
    `}
  ${(props: ContainerProps) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
    ${(props: ContainerProps) =>
    props.flexWrap &&
    css`
      flex-wrap: ${props.flexWrap};
    `}
    @media (max-width: 768px) {
    padding: 0;
  }
`;

export const VmRethink = styled.div`
  padding: 4rem 6rem;
  text-align: center;
  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

export const VmBigHeader = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const VmButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
`;

export const VmSubmitButton = styled.input`
  background: ${theme.colorPrimary};
  outline: none;
  padding: 0.7rem 2.5rem;
  color: ${theme.colorWhite};
  font-weight: 500;
  border-radius: 0.2rem;
  border: none;
  cursor: pointer;
  :hover {
    background: ${theme.colorPrimaryLight};
  }
`;
