import monthlyBg from '../../common/assets/monthly/monthlyBg.png';
import monthlySystem from '../../common/assets/monthly/monthlySystem.png';

export const monthly = {
  sectionOne: {
    header: 'MONTHLY PORTAL',
    background: monthlyBg,
    image: monthlySystem,
    infoOne:
      'Our Monthly portal allows your customers to manager their Monthly Accounts with your garages',
    infoTwo:
      'The portal generates invoices for your Monthly Customers and allows them to make payments online and track their payment history.',
  },
};
