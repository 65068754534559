import * as React from 'react';
import { footerData, FooterDataProps } from './Footer.data';
import {
  VmFooterText,
  VmFooterBox,
  VmFooterHeader,
  VmFooterTextWrapper,
  VmFooterWrapper,
  VmFooter,
} from './Footer.styles';
import { FooterPageProps } from './Footer.types';

export const Footer = (props: FooterPageProps) => {
  const renderText = (value: FooterDataProps, idx: number) => {
    return (
      <VmFooterText
        key={`text-${idx}`}
        onClick={value.onClick.bind(null, props.history, value.path)}
      >
        {value.label}
      </VmFooterText>
    );
  };
  const renderFooter = (value: typeof footerData[0], idx: number) => {
    return (
      <VmFooterBox key={`footer-${idx}`}>
        <VmFooterHeader>{value.header}</VmFooterHeader>
        <VmFooterTextWrapper>{value.data.map(renderText)}</VmFooterTextWrapper>
      </VmFooterBox>
    );
  };

  return (
    <VmFooterWrapper>
      <VmFooter>{footerData.map(renderFooter)}</VmFooter>
    </VmFooterWrapper>
  );
};
