import * as React from 'react';
import { theme } from '../../common/common';
import { Container, Footer } from '../../components';
import { reservation } from './Reservations.data';
import {
  VmBigHeader,
  FlexOne,
  FlexTwo,
  VmInfoContent,
  VmReservationImageBg,
  VmReservations,
  VmReservationsImage,
  VmResDiv,
} from './Reservations.styles';
import { ReservationProps } from './Reservations.types';

export const ReservationsPage = (props: ReservationProps) => {
  React.useEffect(() => {
    const element = document.getElementById('home-page');
    if (element) {
      element.scrollTo(0, 0);
    }
  }, []);

  const renderReservation = () => {
    return (
      <VmReservationImageBg>
        <div>
          <VmBigHeader className="big-header" color={theme.colorBlack}>
            {reservation.sectionOne.header}
          </VmBigHeader>
          <VmInfoContent
            className="big-info"
            width={'100%'}
            color={theme.colorBlack}
          >
            {reservation.sectionOne.infoOne}
          </VmInfoContent>
          <VmInfoContent
            className="big-info"
            width={'100%'}
            color={theme.colorBlack}
          >
            {reservation.sectionOne.infoTwo}
          </VmInfoContent>
          <VmInfoContent
            className="big-info"
            width={'100%'}
            color={theme.colorBlack}
          >
            {reservation.sectionOne.infoThree}
          </VmInfoContent>
        </div>
        <VmResDiv>
          <VmReservationsImage>
            <img src={reservation.sectionOne.image} />
          </VmReservationsImage>
        </VmResDiv>
      </VmReservationImageBg>
    );
  };

  const renderWebSolutions = () => {
    const renderList = (value: string, idx: number) => {
      return <li>{value}</li>;
    };

    return (
      <Container
        flexDirection={'row'}
        background={'none'}
        flexDirectionMobile={'column'}
      >
        <FlexTwo>
          <VmBigHeader color={theme.colorBlack}>
            {reservation.sectionTwo.header}
          </VmBigHeader>
          <VmInfoContent
            className="big-info"
            width={'60%'}
            color={theme.colorBlack}
          >
            {reservation.sectionTwo.infoOne}
          </VmInfoContent>
          <VmInfoContent
            className="big-info"
            width={'80%'}
            color={theme.colorBlack}
          >
            {reservation.sectionTwo.infoTwo}
          </VmInfoContent>
          <ol>{reservation.sectionTwo.infoThree.map(renderList)}</ol>
        </FlexTwo>
        <FlexTwo>
          <VmReservationsImage>
            <img src={reservation.sectionTwo.image} />
          </VmReservationsImage>
        </FlexTwo>
      </Container>
    );
  };

  const renderModernSolution = () => {
    return (
      <div className="center-div">
        <VmBigHeader className="pad-left" color={theme.colorBlack}>
          {reservation.sectionThree.header}
        </VmBigHeader>
        <Container
          background={'none'}
          flexDirection={'row'}
          flexDirectionMobile={'column'}
        >
          <FlexTwo>
            <VmReservationsImage>
              <img src={reservation.sectionThree.image} />
            </VmReservationsImage>
          </FlexTwo>
          <FlexTwo className={'pad-left'}>
            <VmBigHeader color={theme.colorBlack}>
              {reservation.sectionThree.subHeader}
            </VmBigHeader>
            <VmInfoContent
              className="big-info"
              width={'60%'}
              color={theme.colorBlack}
            >
              {reservation.sectionThree.infoOne}
            </VmInfoContent>
          </FlexTwo>
        </Container>
      </div>
    );
  };

  return (
    <VmReservations>
      {renderReservation()}
      {renderWebSolutions()}
      {renderModernSolution()}
      <Footer history={props.history} />
    </VmReservations>
  );
};
