import styled, { css } from 'styled-components';
import { theme } from '../../common/common';
import { ContainerCssProps } from './Container.types';

export const VmContainer = styled.div`
  display: flex;
  flex-direction: ${(props: ContainerCssProps) => props.flexDirection};
  width: 100%;
  padding: 4rem;
  max-width: 100rem;
  margin: 0 auto;
  background-image: url(${(props: ContainerCssProps) =>
    props.image ? props.image : 'none'});
  background-repeat: no-repeat;
  background-color: ${(props: ContainerCssProps) =>
    props.background ? props.background : theme.colorBlack};
  position: relative;
  &.shrink {
    background-size: 75%;
  }
  &.cover {
    background-size: cover;
    background-position: bottom;
  }
  &.gradient {
    background-position: initial;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(${theme.colorPrimary}, ${theme.colorWhite});
      opacity: 0.5;
    }
  }
  &.initial {
    background-size: 100%;
  }
  @media (max-width: 768px) {
    padding: 2rem;
    flex-direction: ${(props: ContainerCssProps) =>
      props.flexDirectionMobile ? props.flexDirectionMobile : 'unset'};
    &.shrink {
      background-position: bottom left;
      background-size: 100%;
    }
    &.reverse {
      flex-direction: column-reverse;
    }
    &.initial {
      background-size: cover;
    }
  }
  ${(props: ContainerCssProps) =>
    props.backgroundShadeColor &&
    css`
      &:after {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(
          ${props.backgroundShadeColor},
          ${props.backgroundShadeColor}
        );
        opacity: 0.8;
      }
    `}
  div {
    z-index: 1;
  }
`;
