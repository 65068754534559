import styled from 'styled-components';
import { theme } from '../../common/common';
import { developer } from './Developer.data';

export const VmDeveloper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${developer.mainBackground});
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  background-position: center;
  @media (max-width: 768px) {
    background-size: cover;
    background-position: center;
    background-position-x: 51%;
  }
`;

export const VmDeveloperImageBg = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4rem;
  position: relative;
  height: calc(100vh - 6rem);
  text-align: center;
  justify-content: center;
  z-index: 1;
  max-width: 100rem;
  margin: 0 auto;
  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(${developer.sectionOne.background});
    opacity: 0.5;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 4rem);
    justify-content: space-around;
    flex-direction: column;
    padding: 1rem;
    &:after {
      background-position: center;
    }
  }
`;

export const VmFlex = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 80%;
  align-items: center;
  justify-content: center;
  position: relative;
  &.inner-padding {
    padding: 3rem 0;
  }
  &.inner-padding-more {
    padding: 5rem 0 5rem 5rem;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const VmInnerBox = styled.div`
  padding: 0 4rem;
  color: ${theme.colorWhite};
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const VmBigHeaderMain = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${theme.colorWhite};
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const VmHeaderContent = styled.div`
  font-size: 1.5rem;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: left;
  }
`;

export const VmHeader = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${theme.colorWhite};
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const VmInfoContent = styled.div`
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color: ${theme.colorWhite};
  width: 60%;
  text-align: left;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
  }
`;

export const VmDevTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const VmDevTile = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem 0;
    text-align: center;
  }
`;

export const VmDevTileHeader = styled.div`
  font-size: 1.5rem;
  font-weight: 1rem;
  padding-bottom: 1rem;
  color: ${theme.colorWhite};
`;

export const VmDevTileText = styled.div`
  color: ${theme.colorWhite};
  text-align: center;
  font-size: 1.3rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const VmDevTileImg = styled.img`
  max-width: 100%;
`;

export const VmDevSwagger = styled.img`
  max-width: 100%;
`;

export const VmDevBtn = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
`;

export const VmSubmitButton = styled.input`
  background: ${theme.colorBlack};
  outline: none;
  padding: 0.7rem 2.5rem;
  color: ${theme.colorWhite};
  font-weight: 500;
  border-radius: 0.2rem;
  border: 1px solid ${theme.colorWhite};
  cursor: pointer;
  :hover {
    background: ${theme.colorGreyDarker};
  }
`;
