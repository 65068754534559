import styled from 'styled-components';
import { theme } from '../../common/common';

export const Body = styled.div`
  overflow: auto;
  widtH 100%;
  background: linear-gradient(${theme.colorBlack},rgba(9,51,67,1));
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${theme.colorGrey};
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colorGreyDark};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${theme.colorGreyDarker};
  }
`;
