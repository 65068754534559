import styled, { css } from 'styled-components';
import { theme } from '../../common/common';
import { monthly } from './Monthly.data';

interface BigHeaderProps {
  color?: string;
}

interface InfoCssProps {
  width?: string;
  color?: string;
  align?: string;
  alignMobile?: string;
}

export const VmMonthly = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${monthly.sectionOne.background});
  background-size: cover;
`;

export const VmBigHeader = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
  padding-bottom: 1rem;
`;

export const VmInfoContent = styled.div`
  font-size: 1.5rem;
  padding-bottom: 2rem;
  color: ${(props: InfoCssProps) =>
    props.color ? props.color : theme.colorWhite};
  width: ${(props: InfoCssProps) => (props.width ? props.width : '60%')};
  text-align: ${(props: InfoCssProps) => (props.align ? props.align : 'left')};
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2rem;
    ${(props: InfoCssProps) =>
      props.alignMobile &&
      css`
        text-align: ${props.alignMobile};
      `}
    &.lpr {
      text-align: center;
    }
  }
`;

export const VmLprImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    height: 100%;
  }
  @media (max-width: 768px) {
    padding: 1rem 0;
    img {
      width: 100%;
    }
  }
`;

export const VmMonthlyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  margin: 0 auto;
  width: 100%;
  padding: 4rem;
  min-height: calc(100vh - 6rem);

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
    height: auto;
    > div {
      width: 100% !important;
    }
  }
`;
