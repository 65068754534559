import * as React from 'react';
import { theme } from '../../common/common';
import { Container, Footer } from '../../components';
import { developer } from './Developer.data';
import {
  VmBigHeaderMain,
  VmDevBtn,
  VmDeveloper,
  VmDeveloperImageBg,
  VmDevSwagger,
  VmDevTile,
  VmDevTileHeader,
  VmDevTileImg,
  VmDevTiles,
  VmDevTileText,
  VmFlex,
  VmHeader,
  VmHeaderContent,
  VmInfoContent,
  VmInnerBox,
  VmSubmitButton,
} from './Developer.styles';
import { DeveloperPageProps } from './Developer.types';

export const DeveloperPage = (props: DeveloperPageProps) => {
  const { history } = props;
  React.useEffect(() => {
    const element = document.getElementById('home-page');
    if (element) {
      element.scrollTo(0, 0);
    }
  }, []);

  const renderTransform = () => {
    return (
      <VmDeveloperImageBg>
        <VmFlex>
          <VmInnerBox>
            <VmBigHeaderMain color={theme.colorWhite}>
              {developer.sectionOne.header}
            </VmBigHeaderMain>
            <VmHeaderContent>{developer.sectionOne.infoOne}</VmHeaderContent>
          </VmInnerBox>
        </VmFlex>
      </VmDeveloperImageBg>
    );
  };

  const renderIntegrations = () => {
    const renderTile = (value: any, idx: number) => {
      return (
        <VmDevTile key={`dev-${idx}`}>
          <VmDevTileImg src={value.image} />
          <VmDevTileHeader>{value.header}</VmDevTileHeader>
          <VmDevTileText>{value.text}</VmDevTileText>
        </VmDevTile>
      );
    };
    return (
      <Container background={'none'} flexDirectionMobile={'column'}>
        <VmHeader>{developer.sectionTwo.header}</VmHeader>
        <VmInfoContent>{developer.sectionTwo.infoText}</VmInfoContent>
        <VmDevTiles>{developer.sectionTwo.data.map(renderTile)}</VmDevTiles>
      </Container>
    );
  };

  const renderDevStack = () => {
    const renderTile = (value: any, idx: number) => {
      return (
        <VmDevTile key={`dev-${idx}`}>
          <VmDevTileImg src={value.image} />
          <VmDevTileHeader>{value.title}</VmDevTileHeader>
        </VmDevTile>
      );
    };
    return (
      <Container background={'none'} flexDirectionMobile={'column'}>
        <VmHeader>{developer.sectionThree.header}</VmHeader>
        <VmDevTiles>{developer.sectionThree.data.map(renderTile)}</VmDevTiles>
      </Container>
    );
  };

  const goToRequest = () => history.push('/contact');

  const renderSwagger = () => {
    return (
      <Container background={'none'} flexDirectionMobile={'column'}>
        <VmDevSwagger src={developer.sectionFour.image} />
        <VmDevBtn>
          <VmSubmitButton
            type="button"
            onClick={goToRequest}
            value={developer.sectionFour.btnText}
          />
        </VmDevBtn>
      </Container>
    );
  };

  return (
    <div>
      <VmDeveloper>
        {renderTransform()}
        {renderIntegrations()}
        {renderDevStack()}
        {renderSwagger()}
        <Footer history={history} />
      </VmDeveloper>
    </div>
  );
};
