import styled, { css } from 'styled-components';
import { theme } from '../../common/common';
import { pos } from './Pos.data';

interface BigHeaderProps {
  color?: string;
}

interface InfoCssProps {
  width?: string;
  color?: string;
  align?: string;
  alignMobile?: string;
}

export const VmPos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${pos.mainBackground});
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  background-position: center;
  @media (max-width: 768px) {
    background-size: cover;
    background-position: center;
    background-position-x: 51%;
  }
`;

export const VmPosImageBg = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem;
  position: relative;
  min-height: calc(100vh - 6rem);
  z-index: 1;
  max-width: 100rem;
  margin: 0 auto;
  &:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(${pos.sectionOne.background});
    opacity: 0.3;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (max-width: 768px) {
    height: auto;
    justify-content: space-around;
    flex-direction: column;
    padding: 1rem;
    &:after {
      background-position: center;
    }
  }
`;

export const VmHeader = styled.div`
  font-size: 3rem;
  font-weight: 600;
  padding: 6rem 0;
  color: ${theme.colorWhite};
  @media (max-width: 768px) {
    font-size: 1.5rem;
    padding: 1rem 0;
  }
`;

export const VmBigHeader = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${(props: BigHeaderProps) =>
    props.color ? props.color : theme.colorBlack};
  padding-bottom: 1rem;
  &.pad-left {
    padding-left: 4rem;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
    &.pad-left {
      padding-left: 1rem;
    }
    &.big-header {
      font-size: 2.8rem;
    }
    &.lpr {
      text-align: center;
    }
  }
`;

export const VmInfoContent = styled.div`
  font-size: 1.3rem;
  padding-bottom: 1rem;
  color: ${(props: InfoCssProps) =>
    props.color ? props.color : theme.colorWhite};
  width: ${(props: InfoCssProps) => (props.width ? props.width : '60%')};
  text-align: ${(props: InfoCssProps) => (props.align ? props.align : 'left')};
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
    ${(props: InfoCssProps) =>
      props.alignMobile &&
      css`
        text-align: ${props.alignMobile};
      `}
    &.lpr {
      text-align: center;
    }
    &.big-info {
      font-size: 1.4rem;
    }
  }
`;

export const FlexOne = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FlexTwo = styled.div`
  width: 50%;
  &.pad-left {
    padding-left: 3rem;
  }
  ol {
    font-size: 1.3rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0rem;
  }
`;

export const VmPosImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    padding-bottom: 2rem;
    max-height: 100%;
    object-fit: cover;
    height: 100%;
  }
  @media (max-width: 768px) {
    padding: 1rem 0;
    img {
      padding-bottom: 0rem;
      width: 100%;
    }
  }
`;

export const VmPosTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  max-width: 100rem;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const VmPosTile = styled.div`
  width: 50%;
  padding: 1rem 3rem 3rem 0;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    padding-bottom: 1rem;
  }
`;

export const VmPosTileHeader = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${theme.colorWhite};
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

export const VmPosTileText = styled.div`
  color: ${theme.colorWhite};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const VmIPADImage = styled.img`
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  left: -10%;
  top: 3rem;
  width: 100%;
`;
